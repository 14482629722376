// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/images/wechat-qr-code.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "h2[data-v-f10682e0]{display:flex;align-items:center}h2 i[data-v-f10682e0]{font-size:12px;margin-left:5px}.support-link[data-v-f10682e0]:not(:last-child){margin-bottom:15px}.wechat-modal[data-v-f10682e0]{margin:60px;display:flex;flex-direction:column;align-items:center}.link[data-v-f10682e0]{cursor:pointer}.btn[data-v-f10682e0]{margin:20px auto 0}.qr-img[data-v-f10682e0]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-size:cover;background-position:50%;height:128px;width:128px;margin:15px auto 10px}", ""]);
// Exports
module.exports = exports;
